<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <b-card
            header="Statistika"
            header-tag="header"
            footer-tag="footer"
            footer=" "
        >
          <b-col cols="12" class="text-center" v-if="!loaded">
            <b-spinner variant="success" label="Spinning"></b-spinner>
          </b-col>

          <b-row>
            <b-col cols="1" v-if="loaded">
              <b-button class="mb-3" @click="getExport()" variant="success">Eksportēt</b-button>
            </b-col>

            <b-col cols="11" v-if="loaded">
              <b-form-group label="Atlasāmie lauki:" v-slot="{ ariaDescribedby }">
                <b-form-checkbox v-for="col in columns" :key="col.prop"
                    v-model="col.show"
                    :value="true"
                    :unchecked-value="false"
                    v-on:input="filterData"
                >
                  {{ col.name }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>



<!--          <div class="grid">-->
<!--            <v-grid theme="material" :beforefilterapply="beforeFilter" :resize="true" :exporting="true" :readonly="true" :source="rows" :columns="filtered" :filter="true"></v-grid>-->
<!--          </div>-->

          <b-col cols="12" v-if="loaded">
            <hot-table :settings="hotSettings" :data="rows" ref="hotTableComponent"></hot-table>
          </b-col>


        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { HotTable } from '@handsontable/vue';
import { registerAllModules } from 'handsontable/registry';

// register Handsontable's modules
registerAllModules();

export default {
  name: "App",
  data() {
    return {
      loaded: false,
      hotSettings: {
        colHeaders: ['Vārds', 'Uzvārds', 'Personas kods', 'Arhivēts', 'Vecums', 'E-pasts', 'Tel.', 'Dzimums', 'Darba veids', 'Darba status', 'Izglītības veids', 'Grāds', 'Kvalifikācija', 'Amats', 'Struktūrvienība', 'Tiešais vadītājs', 'Tiešā vadītāja e-pasts', 'Pieņemts', 'DL termiņš'],
        columns: [
          {
            data: 'name',
            type: 'text',
          },
          {
            data: 'surname',
            type: 'text',
          },
          {
            data: 'personal_code',
            type: 'text',
          },
          {
            data: 'archived',
            type: 'text',
          },
          {
            data: 'age',
            type: 'numeric',
          },
          {
            data: 'email',
            type: 'text',
          },
          {
            data: 'phone',
            type: 'text',
          },
          {
            data: 'gender',
            type: 'text',
          },
          {
            data: 'category',
            type: 'text',
          },
          {
            data: 'status',
            type: 'text',
          },
          {
            data: 'education_type',
            type: 'text',
          },
          {
            data: 'grade',
            type: 'text',
          },
          {
            data: 'qual',
            type: 'text',
          },
          {
            data: 'position',
            type: 'text',
          },
          {
            data: 'department',
            type: 'text',
          },
          {
              data: 'head',
              type: 'text',
          },
          {
              data: 'head_email',
              type: 'text',
          },
          {
            data: 'hired',
            type: 'date',
          },
          {
            data: 'fired',
            type: 'date',
          },
        ],
        hiddenColumns: {
          copyPasteEnabled: false,
          indicators: true
        },
        columnSorting: true,
        manualColumnResize: true,
        fixedColumnsLeft: 4,
        dropdownMenu: true,
        filters: true,
        height: '65vh',

        licenseKey: 'non-commercial-and-evaluation'
      },
      columns: [
        {
          prop: "name",
          name: "Vārds",
          sortable: true,
          pin: 'colPinStart',
          autoSize: true,
          show: true,
        },
        {
          prop: "surname",
          name: "Uzvārds",
          sortable: true,
          pin: 'colPinStart',
          autoSize: true,
          show: true,
        },
        {
          prop: "personal_code",
          name: "Personas kods",
          sortable: true,
          show: true,
        },
        {
          prop: "archived",
          name: "Arhivēts",
          pin: 'colPinEnd',
          sortable: true,
          order: 'desc',
          show: true,
        },
        {
          prop: "age",
          name: "Vecums",
          filter: 'number',
          sortable: true,
          show: true,
        },
        {
          prop: "email",
          name: "E-pasts",
          sortable: true,
          show: true,
        },
        {
          prop: "phone",
          name: "Tel.",
          sortable: true,
          show: true,
        },
        {
          prop: "gender",
          name: "Dzimums",
          filter: "select[]",
          sortable: true,
          show: true,
        },
        {
          prop: "category",
          name: "Darba veids",
          sortable: true,
          show: true,
        },
        {
          prop: "status",
          name: "Darba status",
          sortable: true,
          show: true,
        },
        {
          prop: "education_type",
          name: "Izglītības veids",
          sortable: true,
          show: true,
        },
        {
          prop: "grade",
          name: "Grāds",
          sortable: true,
          show: true,
        },
        {
          prop: "qual",
          name: "Kvalifikācija",
          sortable: true,
          show: true,
        },
        {
          prop: "position",
          name: "Amats",
          sortable: true,
          show: true,
        },
        {
          prop: "department",
          name: "Struktrūvienība",
          sortable: true,
          show: true,
        },
        {
          prop: "head",
          name: "Tiešais vadītājs",
          sortable: true,
          show: true,
        },
        {
          prop: "head_email",
          name: "Tiešā vadītāja e-pasts",
          sortable: true,
          show: true,
        },
        {
          prop: "hired",
          name: "Pieņemts",
          sortable: true,
          show: true,
        },
        {
          prop: "fired",
          name: "Atlaists",
          sortable: true,
          show: true,
        },
      ],
      rows: [],
      filtered: [],
    };
  },
  beforeMount() {
    this.filtered = [];
  },
  mounted() {
    this.getUserStatistics();

  },
  methods: {
    async getUserStatistics() {
      await axios.get(`/users/statistics`).then(response => {
        this.rows = response.data.data;
        console.log(this.rows);
        this.loaded = true;
      })
    },
    getExport() {
      const exportPlugin = this.$refs.hotTableComponent.hotInstance.getPlugin('exportFile');
      exportPlugin.downloadFile('csv', {
        filename: new Date(),
        columnDelimiter: ';',
        columnHeaders: true,
      });

    },
    filterData() {
      this.filtered = [];
      const plugin = this.$refs.hotTableComponent.hotInstance.getPlugin('hiddenColumns');

      this.columns.forEach((e, key) => {
        if(e.show === false) {
          plugin.hideColumn(key);
        } else {
          plugin.showColumn(key);
        }
      })
      this.$refs.hotTableComponent.hotInstance.render();
      console.log([this.filtered, this.columns]);
    },
    beforeFilter() {
      console.log('filter');
    },
  },
  components: {
    HotTable
  }
};
</script>

<style src="../../../node_modules/handsontable/dist/handsontable.full.css"></style>

<style lang="scss" scoped>
.custom-control {
  position: relative;
  z-index: 1;
  display: inline-block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 0.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
</style>
